@use '@angular/material' as mat;
@import './app/scss/vars.scss';
@import './app/scss/section-header.scss';
@import './app/scss/table.scss';
@import './app/scss/status-indicator.scss';
@import './app/scss/drag-drop.scss';
@import './app/scss/drawer.scss';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$bleachr-cvl-moderator-primary: mat.define-palette(mat.$indigo-palette);
$bleachr-cvl-moderator-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$bleachr-cvl-moderator-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$bleachr-cvl-moderator-theme: mat.define-light-theme(
    (
        color: (
            primary: $bleachr-cvl-moderator-primary,
            accent: $bleachr-cvl-moderator-accent,
            warn: $bleachr-cvl-moderator-warn
        )
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($bleachr-cvl-moderator-theme);

/* You can add global styles to this file, and also import other style files */

// html,
// body {
//     height: 100%;
// }
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}
//
// Dark mode
//

$bleachr-cvl-moderator-primary-darkmode: mat.define-palette(mat.$deep-purple-palette, 400, 100, 400);

$angular-dark-theme: mat.define-dark-theme(
    (
        color: (
            primary: $bleachr-cvl-moderator-primary-darkmode,
            accent: $bleachr-cvl-moderator-accent,
            warn: $bleachr-cvl-moderator-warn
        )
    )
);

@include mat.all-component-themes($angular-dark-theme);

@media (prefers-color-scheme: dark) {
    @include mat.all-component-colors($angular-dark-theme);

    body {
        background-color: darken($bleachr-purple, 28%);
    }

    mat-form-field {
        color: $bleachr-white;
    }
}

@media (prefers-color-scheme: light) {
    @include mat.all-component-colors($bleachr-cvl-moderator-theme);
}

.plyr {
    height: 100%;
}

.checkbox-options-list {
    display: flex;
    flex-direction: column;
    padding: 0px 16px;

    mat-checkbox {
        height: 48px;
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        label {
            width: calc(100% - 16px);

            .mat-checkbox-label {
                width: 100%;
            }
        }
    }
}
