.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
    opacity: 0;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.dragable-list.cdk-drop-list-dragging .dragable-item:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.timeline.cdk-drop-list-dragging .timeline-item:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

// Mat list items

mat-list-option {
    &.cdk-drag-preview {
        .mat-list-item-content {
            display: flex !important;
            align-items: center !important;
            width: 100%;
            height: 100%;
            flex-direction: row-reverse !important;

            mat-pseudo-checkbox {
                margin-right: 0.5rem;
            }

            .mat-list-text {
                flex: 1;

                .option-contents {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    margin: 0rem 0.5rem;
                }
            }
        }
    }
}

@media (prefers-color-scheme: dark) {
    mat-list-option {
        &.cdk-drag-preview {
            .mat-list-item-content {
                color: $bleachr-white;
                background-color: $bleachr-purple;
            }
        }
    }
}
