@import './vars.scss';

app-drawer {
    width: 650px;
    background-color: $bleachr-purple;
    height: 100vh;
    position: fixed;
    top: 0;
    right: -650px;
    z-index: 101;
    transition: all 0.5s ease-out;
    border-left: 1px solid darken($bleachr-purple, 10%);
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: 4px 0px 8px 0px rgb(0 0 0 / 30%);

    &.active {
        right: 0;
    }

    .input-container {
        display: flex;
        flex-direction: column;
        padding: 1.5rem 1.5rem 0rem;
        margin-bottom: 4.25rem !important;

        .locale-input-container {
            display: flex;
            align-items: baseline;

            .input-label {
                border: 1px solid;
                border-right: 0;
                padding: 24px 1rem 15px 1rem;
                margin-right: -4px;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                border-color: #dcdcdc;
                text-transform: uppercase;
                font-weight: 600;
            }

            mat-form-field {
                flex: 1;
            }
        }
    }

    app-drawer-footer {
        position: fixed;
        bottom: 0;
        border-top: 1px solid darken($bleachr-purple, 10%);
        width: calc(650px - 3rem);
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        background-color: lighten($bleachr-purple, 4%);
        z-index: 2;
    }
}
