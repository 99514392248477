@import './vars.scss';

.circle-indicator {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $bleachr-off-white;
    margin: 0rem 0.25rem;

    &.enabled,
    &.complete,
    &.success,
    &.live,
    &.open,
    &.approved {
        background-color: $bleachr-green;
    }

    &.disabled,
    &.closed,
    &.archived {
        background-color: $bleachr-dark-gray;
    }

    &.hidden,
    &.unapproved {
        background-color: $bleachr-red;
    }
}
