@import '../scss/vars.scss';

.section-header {
    padding: 1rem;
    background: $bleachr-purple;
    color: $bleachr-white;
    border-radius: $border-radius;
    margin: 1rem 2rem;
    font-size: 1.1rem;
}
