$bleachr-purple: #5753c1;
$bleachr-white: #fefefe;
$bleachr-off-white: #e0e0e0;
$bleachr-green: #4caf50;
$bleachr-red: #f44336;
$bleachr-blue: #2196f3;
$bleachr-gray: #90a4ae;
$bleachr-yellow: #ffde03;
$bleachr-black: #000000;
$bleachr-dark-gray: #78909c;
//
$bleachr-border-color: #e0e0e0;
$bleachr-hover-color: #f5f5f5;
$bleachr-pink: #DD4BA0;
//
$border-radius: 4px;
