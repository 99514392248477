@import './vars.scss';

.table-container {
    max-height: calc(100vh - 12rem);
    // overflow: auto;
    margin: 0rem 2rem;
    border-radius: $border-radius;

    table {
        width: 100%;

        tr {
            &:hover {
                cursor: pointer;
                filter: brightness(125%);
            }
        }
    }
}
